const text = {
  intro: "Hi, my name is",
  myNameIs: "ADAM DOYLE",
  wat:
    "I'm a software engineer specializing in developing high-quality mobile and web applications.",
  watTwo:
    " specializing in developing high-quality mobile and web applications.",
  contact: "Get in Touch",
  details:
    "Currently living in Orange County California and building software solutions at Beacon Biosignals",
};
export default text;
