const colors = {
    background: '#2A2A2A',
    white: '#E5E5E5',
    grey: '#848586',
    blue: '#16ADC2',
    primary: '#401786',
    secondary: '#9880C1',
    third: '#38E5F2',
}

export default colors